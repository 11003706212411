<template>
  <ReportToolbar
    :show-hide-items="showHideItems"
    @on-search="$emit('on-search')"
  >
    <ToolbarButton
      :label="$t('Main.Ui.acViewOnOrder')"
      data-toolbar-item
      @click.native="openInTransition"
    >
      <icon
        data="@icon/in_transition.svg"
        class="size-18"
      />
    </ToolbarButton>
    <ToolbarMenuButton
      :label="$t('Main.Ui.acExportInventory')"
      :items="exportTableItems"
      :click-handler="handleExportToXlsx"
      @[exportTableKeys.DB]="handleExportToDatabase"
      @[exportTableKeys.XLSX]="handleExportToXlsx"
    >
      <template #icon>
        <icon
          data="@icon/table.svg"
          class="size-16"
        />
      </template>
    </ToolbarMenuButton>
    <ToolbarButton
      :label="$t('Main.Ui.acExportSmallInvToXlsx')"
      data-toolbar-item
      @click.native="handleParamsToXlsx"
    >
      <icon
        data="@icon/export.svg"
        class="size-18"
      />
    </ToolbarButton>
    <ToolbarButton
      :disabled="!$sl_tabEditable"
      data-toolbar-item
      @click.native="handleImportParams"
    >
      <icon
        data="@icon/import.svg"
        class="size-18"
      />
      <div class="toolbar-button__label">
        {{ $t('Main.Ui.acImportInventoryFromXlsx') }}
      </div>
      <input
        v-show="false"
        ref="uploader"
        type="file"
        :accept="xlsxAccepted"
        @change="onFileChanged"
      >
    </ToolbarButton>
    <ToolbarButton
      v-if="displayCollections"
      :label="$t('Main.Ui.acEditCollectionsInv')"
      :disabled="!$sl_tabEditable"
      data-toolbar-item
      @click.native="openContainers"
    >
      <icon
        data="@icon/container.svg"
        class="size-16"
      />
    </ToolbarButton>
    <ToolbarButton
      v-if="displayConstrainedPlan"
      :label="$t('Web.ConstrainedPlan.PageTitle')"
      data-toolbar-item
      @click.native="openConstrainedPlan"
    >
      <icon
        data="@icons/constrained_plan.svg"
        class="size-16 fill-off"
      />
    </ToolbarButton>
    <ToolbarButton
      v-tooltip="getTooltip({
        content: $t('Web.PlannedOrders.OverdatedTooltip'),
        disabled: !isOverdated
      })"
      :label="$t('Main.Ui.acExportPeriodicToErp')"
      :disabled="isOverdated"
      data-toolbar-item
      @click.native="openPlannedOrders"
    >
      <icon
        data="@icon/planned_orders.svg"
        class="size-18"
      />
    </ToolbarButton>
  </ReportToolbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import ReportToolbar from '@/components/Report/ReportToolbar.vue';
import ToolbarButton from '@/components/Toolbar/ToolbarButton.vue';
import ToolbarMenuButton from '@/components/Toolbar/ToolbarMenuButton.vue';
import { fileSaver } from '@/mixins/webAPI';
import modalsId from '@/config/shared/modalsId.config';
import { exportTableKeys } from '@/config/report';
import { routeNames } from '@/config/router/router.config';
import { namespaceByRoute } from '@/config/report';
import { supportedExportFgs } from '@/config/shared/fgs.config';
import { xlsxAccepted } from '@/config/utils/fileUpload.config';
import { plannedOrdersRoute } from '@/config/report/inventoryReport/plannedOrders.config';
import { inTransitionRoute } from '@/config/report/inventoryReport/inTransition.config';
import { MB100 } from '@/helpers/file/fileSize';
import { getTooltip } from '@/helpers/shared/tooltip';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'InventoryReportToolbar',
  components: {
    ReportToolbar,
    ToolbarButton,
    ToolbarMenuButton
  },
  mixins: [modal, access, fileSaver],
  props: {
    showHideItems: {
      type: Array,
      required: true
    }
  },
  inject: [
    'handleExportToDatabase'
  ],
  data() {
    return {
      xlsxAccepted,
      modalsId,
      routeNames,
      exportTableKeys,
      orders: [],
      ordersLoading: !!this.orders,
      selectedFile: null
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      isOverdated: state => state.project.project?.isOverdated
    }),
    ...mapGetters({
      canExport: 'project/canExport',
      hasCollections: 'project/hasCollections'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    canCreate() {
      return this.orders ? this.orders.canCreate : false;
    },
    displayCollections() {
      return this.$sl_routeName !== this.routeNames.INTERSTORE && this.hasCollections;
    },
    displayConstrainedPlan() {
      return this.$sl_routeName !== this.routeNames.INTERSTORE;
    },
    exportTableItems() {
      return [
        {
          title: this.$t('Main.Ui.acChooseExportToXlsx'),
          value: exportTableKeys.XLSX
        },
        {
          title: this.$t('Main.Ui.acChooseExportToDatabase'),
          value: exportTableKeys.DB,
          disabled: this.isExportToDatabaseDisabled
        }
      ];
    },
    isExportToDatabaseDisabled() {
      // fgs inv = dc = opt
      return !(this.canExport & supportedExportFgs.INVENTORY) || !this.$sl_hasAccess(this.$sl_features.exportTables);
    }
  },
  methods: {
    ...mapActions({
      exportTableToXlsx: 'inventoryReport/exportTableToXlsx',
      exportParamsToXlsx: 'inventoryReport/exportParamsToXlsx',
      importParams: 'inventoryReport/importParams'
    }),
    getTooltip,
    async handleExportToXlsx() {
      this.saveFile(this.exportTableToXlsx.bind(this));
    },
    async handleParamsToXlsx() {
      this.saveFile(this.exportParamsToXlsx.bind(this));
    },
    async handleImportParams() {
      if (!this.$sl_tabEditable) {
        return;
      }

      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];

      if (this.selectedFile?.size > MB100) {
        this.showModal(modalsId.NOTIFY, {
          title: this.$t('Error.Error'),
          text: this.$t('Web.File.MaxFileSizeInKb', {
            1: MB100 / 1024
          })
        });

        this.$refs.uploader.value = '';

        return;
      }

      try {
        await this.importParams(this.selectedFile);
      } catch (error) {
        const message = error?.message;
        const description = error?.description;
        const errorMessage = `${description}: ${message}`;

        this.showModal(modalsId.NOTIFY, {
          title: this.$t('Main.Ui.acImportInventoryFromXlsx'),
          text: (message && description) ? errorMessage : this.$t('Error.HeadImportFromXlsx')
        });
      } finally {
        this.$refs.uploader.value = '';

        ampli.inventoryTableImported({
          'Project Id': this.projectId
        });
      }
    },
    openContainers() {
      this.$router.push({
        name: routeNames.COLLECTIONS
      });
    },
    openConstrainedPlan() {
      this.$router.push({
        name: routeNames.CONSTRAINED_PLAN
      });
    },
    openPlannedOrders() {
      this.$router.push({
        name: plannedOrdersRoute[this.$sl_routeName]
      });
    },
    openInTransition() {
      this.$router.push({
        name: inTransitionRoute[this.$sl_routeName]
      });
    }
  }
};
</script>
