<template>
  <SlContextMenu
    ref="contextMenu"
    :element-id="menuElementId"
    :options="tableContextMenuOptions"
    @option-clicked="handleOptionClick"
  >
    <template #[contextMenuKeys.FILTER_BY]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.FILTER_BY, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.FILTER_BY, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.FILTER_ADD]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.ADD_TO_FILTER, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.ADD_TO_FILTER, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.FILTER_BY_BOM]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.FILTER_BY_BOM, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.FILTER_BY_BOM, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.COPY_ITEM_TO_CLIPBOARD]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.COPY_ITEM_CODE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.COPY_ITEM_CODE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.COPY_ITEM.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.FIND_IN_DEMAND]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.FIND_IN_DEMAND, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.FIND_IN_DEMAND, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.FIND_IN_INVENTORY]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.FIND_IN_IR, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.FIND_IN_IR, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.FIND_IN_DC]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.FIND_IN_DC, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.FIND_IN_DC, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.FIND_IN_REPORTS]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.FIND_IN_FR, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.FIND_IN_FR, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.ITEM_BOM]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.ITEM_BOM, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.ITEM_BOM, fgsActionTypes.ENABLED)"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.OPEN_MATERIAL_PRODUCT_TREE.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.REMOVE_FROM_IMPORT]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.REMOVE_FROM_IMPORT, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.REMOVE_FROM_IMPORT, fgsActionTypes.ENABLED)"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.REMOVE_FROM_IMPORT.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.SUBSTITUTE]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.SUBSTITUTE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.SUBSTITUTE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.SELECT_ALL]="{ handler, name }">
      <OptionItem
        :handler="handler"
      >
        {{ name }}
        <template #shortcut>
          <SlShortcut
            v-for="value in shortcutsConfig.SELECT_ALL_ROWS.shortcut"
            :key="value"
            :value="value"
          />
        </template>
      </OptionItem>
    </template>
    <template #[contextMenuKeys.EDIT]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.EDIT, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.EDIT, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.COPY_QTY_TO_OVERRIDE]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.COPY_QTY_TO_OVERRIDE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.COPY_QTY_TO_OVERRIDE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.ADD_NOTE]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.ADD_NOTE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.ADD_NOTE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.EDIT_NOTE]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.EDIT_NOTE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.EDIT_NOTE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.CLEAR_OVERRIDES]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.CLEAR_OVERRIDES, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.CLEAR_OVERRIDES, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.EXPLAIN_CALCULATION]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.EXPLAIN_CALCULATION, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.EXPLAIN_CALCULATION, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
    <template #[contextMenuKeys.RESET_NEXT_ORDER_DATE]="{ handler, name }">
      <OptionItem
        v-if="getActionFgs(contextMenuFgs.RESET_NEXT_ORDER_DATE, fgsActionTypes.VISIBLE)"
        :handler="handler"
        :disabled="!getActionFgs(contextMenuFgs.RESET_NEXT_ORDER_DATE, fgsActionTypes.ENABLED)"
      >
        {{ name }}
      </OptionItem>
    </template>
  </SlContextMenu>
</template>

<script>
import { mapActions } from 'vuex';
import { access } from '@/mixins/access';
import { modal } from '@/mixins/modal';
import SlContextMenu from '@/components/Shared/SlContextMenu/Index.vue';
import OptionItem from '@/components/Shared/SlContextMenu/OptionItem.vue';
import { routeNames } from '@/config/router/router.config';
import { contextMenuFgs } from '@/config/shared/fgs.config';
import modalsId from '@/config/shared/modalsId.config';
import {
  fgsActionTypes,
  contextMenuKeys,
  inventoryContextMenuActionsMap,
  inventoryTableContextMenuOptions
} from '@/config/shared/contextMenu.config';
import { noteTypes } from '@/config/shared/note.config';
import { filterByKeys as filterByTypes, namespaceByRoute } from '@/config/report';
import { shortcutsConfig } from '@/config/shortcuts/shortcuts.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { getFindInQuery } from '@/helpers/report/inventoryReport';
import { copyToClipboard } from '@/helpers/utils/clipboard';

export default {
  name: 'TableContextMenu',
  components: {
    SlContextMenu,
    OptionItem
  },
  mixins: [access, modal],
  props: {
    selectedItems: {
      type: Array,
      required: true
    },
    colIndex: {
      type: Number,
      required: false,
      default: 0
    },
    cellIndex: {
      type: Number,
      required: false,
      default: 0
    },
    allRowsCount: {
      type: Number,
      required: true,
      default: 0
    },
    nested: Boolean
  },
  data() {
    return {
      fgsActionTypes,
      contextMenuFgs,
      contextMenuKeys,
      numColTypes: [
        'int',
        'uint',
        'double',
        'float',
        'percent'
      ]
    };
  },
  inject: [
    'locationByIndex',
    'channelByIndex',
    'itemByIndex',
    'linkPayloadByIndex'
  ],
  computed: {
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    table() {
      return this.$store.state[this.reportNamespace].table?.body;
    },
    head() {
      return this.$store.state[this.reportNamespace].table?.head.col;
    },
    menuElementId() {
      return `table-context-menu${this.nested ? '--nested' : ''}`;
    },
    cellItem() {
      return this.table[this.colIndex]?.col?.[this.cellIndex];
    },
    selectedRowItemCode() {
      const item = this.itemByIndex(this.cellIndex);

      return item?._val || null;
    },
    tableContextMenuOptions() {
      return inventoryTableContextMenuOptions(this);
    },
    isReports() {
      return this.$sl_routeName === routeNames.REPORTS;
    },
    isSelectedUnclearableOverrideRows() {
      if (!this.selectedItems.length || this.isAllRowsSelected) {
        return false;
      }

      return this.selectedItems.some(cellIndex => {
        return !this.getRowActionFgs(cellIndex, contextMenuFgs.CLEAR_OVERRIDES, fgsActionTypes.ENABLED);
      });
    },
    nodeThings() {
      return {
        type: this.isReports ? 'tuple' : 'entry',
        item: this.selectedRowItemCode,
        loc: this.locationByIndex(this.cellIndex)?._val || '',
        chan: this.channelByIndex(this.cellIndex)?._val || ''
      };
    },
    shortcutsConfig() {
      return shortcutsConfig(this).INVENTORY_REPORTS_TABLE.values;
    },
    isAllRowsSelected() {
      return this.allRowsCount === this.getSelectedEntries().length;
    }
  },
  methods: {
    ...mapActions({
      getItemClones: 'substitutions/getItemClones',
      clearOverrides: 'inventoryReport/clearOverrides',
      setCurrentNodeThing: 'note/setCurrentNodeThing',
      setCurrentNote: 'note/setCurrentNote',
      resetNextOrderDate: 'inventoryReport/resetNextOrderDate',
      copyQtyToOverride: 'inventoryReport/copyQtyToOverride'
    }),
    setActiveFilterId(namespace, index) {
      this.$store.dispatch(`${namespace}/setActiveFilterId`, index);
    },
    getCellActionFgs(colIndex, cellIndex) {
      const cell = this.table?.[colIndex]?.col?.[cellIndex];

      return {
        [fgsActionTypes.ENABLED]: cell?._enabledAcs,
        [fgsActionTypes.VISIBLE]: cell?._visibleAcs
      };
    },
    getActionFgs(action, type) {
      const cellActionFgs = this.getCellActionFgs(this.colIndex, this.cellIndex);

      if (typeof action === 'bigint') {
        return !!(BigInt(cellActionFgs[type] || 0) & action);
      }

      return !!(cellActionFgs[type] & action);
    },
    getRowActionFgs(cellIndex, action, type) {
      const rowActionFgs = this.getCellActionFgs(0, cellIndex);

      return !!(rowActionFgs[type] & action);
    },
    getFindInQueryData(isReports = false) {
      const { type, chan, ...params } = this.nodeThings;

      if (isReports) {
        return {
          ...params,
          chan
        };
      }

      return params;
    },
    getSelectedEntries() {
      return this.selectedItems.reduce((items, itemIndex) => {
        items.push({
          _item: this.itemByIndex(itemIndex)?._val,
          _location: this.locationByIndex(itemIndex)?._val || ''
        });

        return items;
      }, []);
    },
    copyCell() {
      const value = this.cellItem?._val || '';

      copyToClipboard(value);
    },
    copyItemCode() {
      copyToClipboard(this.selectedRowItemCode);
    },
    clearRowOverridesCheck() {
      if (this.isSelectedUnclearableOverrideRows) {
        return this.showModal(modalsId.NOTIFY, {
          title: this.$t('Common.Info'),
          text: this.$t('Info.SomeNotChanged'),
          confirmCallback: this.clearInventoryOverrides
        });
      }

      this.clearInventoryOverrides();
    },
    async clearInventoryOverrides() {
      try {
        await this.clearOverrides({
          rows: this.isAllRowsSelected ? [] : this.getSelectedEntries()
        });

        this.$notify({
          type: 'success',
          title: `${this.$t('Main.Ui.acClearOverrides')} done!`
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          title: `${this.$t('Main.Ui.acClearOverrides')} error!`
        });
      }
    },
    handleSubstitute() {
      this.$emit('substitute');
    },
    handleRemoveFromImport() {
      this.$emit('remove-from-import');

    },
    handleSelectAll() {
      this.$emit('select-all');
    },
    handleEdit(event) {
      this.$emit('edit-cell', event);
    },
    handleClearOverrides() {
      const itemsCount = this.selectedItems.length;

      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_warning_double',
        title: this.$t('Web.Modals.ActionConfirm.TitleClearOverrides'),
        text: this.$t(itemsCount > 1
          ? 'Web.Modals.ActionConfirm.TextClearOverridesMany'
          : 'Web.Modals.ActionConfirm.TextClearOverridesOne',
        { 1: itemsCount },
        { 2: this.$t('Web.Pluralization.Item.val', {
          p1: this.$tc('Web.Pluralization.Item.p1', itemsCount, { n: itemsCount })
        })}
        ),
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonClear'),
        confirmCallback: this.clearRowOverridesCheck
      });
    },
    async handleEditNote() {
      await this.setCurrentNodeThing(this.nodeThings);

      const item = this.itemByIndex(this.cellIndex);
      const {
        body,
        _author: author,
        _editTime: editTime
      } = item?.tooltip?.note || {};

      await this.setCurrentNote({
        note: {
          body,
          author,
          editTime
        },
        canEdit: false,
        type: noteTypes.NODE
      });

      await this.showModal(modalsId.ADD_NOTE);
    },
    handleExplainCalculation() {
      this.$emit('explain-calculation');
    },
    getColumnKey() {
      const { _columnKey: columnKey } = this.head[this.colIndex];

      return { columnKey };
    },
    getFilterValue() {
      const { _type: type } = this.head[this.colIndex];
      const { _edit: edit, _val: val } = this.cellItem || {};
      const preparedValue = val?.replace(/[^0-9.—-]/g, '');

      if (!this.numColTypes.includes(type)) {
        return val || null;
      }

      if (edit) {
        return +edit;
      }

      if (!isNaN(+preparedValue)) {
        return +preparedValue;
      }

      return null;
    },
    async filterBy() {
      await this.$store.dispatch(`${this.reportNamespace}/filterBy`, {
        filterBy: filterByTypes.COLUMN,
        val: this.getFilterValue(),
        ...this.getColumnKey()
      });

      this.showModal(modalsId.REPORT_FILTER);
    },
    async filterByRelatedBOMs({ item }) {
      await this.$store.dispatch(`${this.reportNamespace}/filterBy`, {
        filterBy: filterByTypes.RELATED_BOMs,
        item: this.itemByIndex(item)?._val,
        location: this.locationByIndex(item)?._val || ''
      });

      this.showModal(modalsId.REPORT_FILTER);
    },
    async addToFilter() {
      await this.$store.dispatch(`${this.reportNamespace}/addToFilter`, {
        filterBy: filterByTypes.COLUMN,
        val: this.getFilterValue(),
        ...this.getColumnKey()
      });

      this.showModal(modalsId.REPORT_FILTER, {
        filterId: this.$store.state[`${this.reportNamespace}`].active_filter_id
      });
    },
    async handleFindInInventory() {
      await this.setActiveFilterId(namespaceByRoute[routeNames.INVENTORY], DEFAULT_FILTER_ID);

      this.$router.push({
        name: routeNames.INVENTORY,
        query: {
          search: getFindInQuery(this.getFindInQueryData())
        },
        params: {
          link: true
        }
      });
    },
    async handleFindInReports() {
      await this.setActiveFilterId(namespaceByRoute[routeNames.REPORTS], DEFAULT_FILTER_ID);

      this.$router.push({
        name: routeNames.REPORTS,
        query: {
          search: getFindInQuery(this.getFindInQueryData(true))
        },
        params: {
          link: true
        }
      });
    },
    handleFindInDemand() {
      this.$emit('find-in-demand', this.cellIndex);
    },
    handleOpenBoms() {
      this.$emit('open-bom', this.cellIndex);
    },
    handleResetNextOrderDate() {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.TextConfirmResetNextOrderDate'),
        icon: 'style_warning_double',
        cancelText: this.$t('Common.No'),
        confirmText: this.$t('Common.Yes'),
        confirmCallback: this.resetNextOrderDateConfirm
      });
    },
    resetNextOrderDateConfirm() {
      this.resetNextOrderDate(this.getSelectedEntries());
    },
    handleCopyQtyToOverride() {
      if (this.selectedItems.length === this.allRowsCount) {
        return this.copyQtyToOverride([]);
      }

      const entities = this.selectedItems.reduce((items, itemIndex) => {
        items.push({
          item: this.itemByIndex(itemIndex)?._val,
          location: this.locationByIndex(itemIndex)?._val || ''
        });

        return items;
      }, []);

      this.copyQtyToOverride(entities);
    },
    handleOptionClick(event) {
      if (!event?.option?.key) {
        return;
      }

      const action = inventoryContextMenuActionsMap[event.option.key];

      if (this[action]) {
        this[action](event);
      }
    }
  }
};
</script>

<style scoped>

</style>
