<template>
  <ReportToolbar
    :show-hide-items="showHideItems"
    class="reports-toolbar"
    @on-search="$emit('on-search')"
  >
    <div
      v-for="control in toolbarData"
      :key="control.requestKey"
      class="report-toolbar__option"
      data-toolbar-item
    >
      <v-checkbox
        v-if="control.type === controlType.checkbox"
        :id="control.requestKey"
        v-model="control.val"
        :disabled="!control.enabled"
        class="sl-checkbox"
        @change="setCheckbox(control)"
      />
      <label
        v-if="isLabel(control)"
        :for="control.requestKey"
        class="report-toolbar__title"
      >
        {{ control.name }}
      </label>
      <SlNumberInput
        v-if="control.type === controlType.numberInput"
        :id="control.requestKey"
        v-model="control.val"
        :value="+control.val"
        :disabled="!control.enabled"
        :min="+control.min"
        :max="+control.max"
        :step="1"
        class="sl-number-input--white"
        @input="(val) => setControl({ [control.requestKey]: +val })"
      />
      <v-select
        v-else-if="control.type === controlType.select"
        :items="getItems(control.selection)"
        :value="getValue(control.val, control.selection)"
        item-text="label"
        class="sl-select sl-select--white"
        dense
        solo
        @change="(val) => changeSelect(val, control)"
      />
      <v-menu
        v-else-if="control.type === controlType.comboBox"
        content-class="sl-menu col-visibility-menu aggregation"
        :close-on-content-click="false"
        max-height="auto"
        offset-y
      >
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            class="sl-menu-activator aggregation__title"
            v-on="on"
          >
            <div class="aggregation__label">
              {{ getAggregateLabel(control.selection) }}
            </div>
            <v-icon slot="append">
              mdi-menu-down
            </v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in control.selection"
            :key="index"
            class="aggregation__option"
          >
            <v-checkbox
              :id="item.value"
              v-model="item.status"
              class="sl-checkbox aggregation__checkbox"
              :class="{
                'sl-checkbox--is-hidden': item.noCheckbox
              }"
              @change="setControl({ [control.requestKey]: item.value })"
            />
            <label
              :for="item.value"
              class="aggregation__label"
            >
              <v-list-item-title>
                {{ item.label }}
              </v-list-item-title>
            </label>
          </v-list-item>
        </v-list>
      </v-menu>
      <ToolbarButton
        v-if="control.type === controlType.button"
        :disabled="!control.enabled"
        @click.native="handleImportChanges"
      >
        <icon
          data="@icon/import.svg"
          class="size-18"
        />
        <div class="toolbar-button__label">
          {{ $t('Main.Ui.acImportListFromXlsx') }}
        </div>
        <input
          v-show="false"
          ref="uploader"
          type="file"
          :accept="xlsxAccepted"
          @change="onFileChanged"
        >
      </ToolbarButton>
    </div>

    <ToolbarMenuButton
      :label="$t('Main.Ui.acExportForecast')"
      :items="exportTableItems"
      :click-handler="() => handleExportToXlsx(false)"
      @[exportTableKeys.DB]="handleExportToDatabase"
      @[exportTableKeys.XLSX]="handleExportToXlsx(false)"
      @[exportTableKeys.XLSX_IN_TRANS_FORMAT]="handleExportToXlsx(true)"
    >
      <template #icon>
        <icon
          data="@icon/export.svg"
          class="size-18"
        />
      </template>
    </ToolbarMenuButton>
  </ReportToolbar>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ReportToolbar from '@/components/Report/ReportToolbar.vue';
import ToolbarButton from '@/components/Toolbar/ToolbarButton.vue';
import ToolbarMenuButton from '@/components/Toolbar/ToolbarMenuButton.vue';
import SlNumberInput from '@/components/Shared/Form/SlNumberInput.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { fileSaver } from '@/mixins/webAPI';
import modalsId from '@/config/shared/modalsId.config';
import { supportedExportFgs } from '@/config/shared/fgs.config';
import { exportTableKeys } from '@/config/report';
import { namespaceByRoute } from '@/config/report';
import { xlsxAccepted } from '@/config/utils/fileUpload.config';
import { controlType } from '@/config/report/forecastReport.config';
import { DISABLED_STYLE } from '@/config/report';
import { getColValueWithEntry } from '@/helpers/locale/getColValueWithEntry.js';
import { MB100 } from '@/helpers/file/fileSize';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'ForecastReportToolbar',
  components: {
    ReportToolbar,
    ToolbarButton,
    ToolbarMenuButton,
    SlNumberInput
  },
  mixins: [modal, access, fileSaver],
  props: {
    showHideItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      xlsxAccepted,
      getColValueWithEntry,
      selectedFile: null,
      exportTableKeys,
      controlType,
      reportKey: 'ReportType',
      reportActualSales: 'Plan_fact'
    };
  },
  inject: [
    'handleExportToDatabase'
  ],
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      toolbarData: (state) => state.forecastReport.toolbar
    }),
    ...mapGetters({
      canExport: 'project/canExport'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    exportTableItems() {
      return [
        {
          title: this.$t('Main.Ui.acChooseExportToXlsx'),
          value: exportTableKeys.XLSX,
          transposed: false
        },
        {
          title: this.$t('Web.File.XLSXTransactionalFormal'),
          value: exportTableKeys.XLSX_IN_TRANS_FORMAT,
          transposed: true,
          disabled: !this.isActualSales
        },
        {
          title: this.$t('Main.Ui.acChooseExportToDatabase'),
          value: exportTableKeys.DB,
          disabled: this.isExportToDatabaseDisabled
        }
      ];
    },
    isExportToDatabaseDisabled() {
      return !(this.canExport & supportedExportFgs.FORECAST_REPORT)
        || !this.$sl_hasAccess(this.$sl_features.exportTables);
    },
    isActualSales() {
      const ReportType = this.toolbarData.find(val => val.requestKey === this.reportKey)?.val;

      return ReportType === this.reportActualSales;
    }
  },
  methods: {
    ...mapActions({
      exportTableToXlsx: 'forecastReport/exportTableToXlsx',
      importChangesFromXlsx: 'forecastReport/importChangesFromXlsx',
      setControl: 'forecastReport/setReportToolbar'
    }),
    async handleExportToXlsx(value) {
      this.saveFile(this.exportTableToXlsx.bind(this, value));
    },
    async handleImportChanges() {
      if (!this.$sl_tabEditable) {
        return;
      }

      this.$refs.uploader[0].click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];

      if (this.selectedFile?.size > MB100) {
        this.showModal(modalsId.NOTIFY, {
          title: this.$t('Error.Error'),
          text: this.$t('Web.File.MaxFileSizeInKb', {
            1: MB100 / 1024
          })
        });

        this.$refs.uploader[0].value = '';

        return;
      }

      try {
        const response = await this.importChangesFromXlsx(this.selectedFile);
        const message = response?.data?.payload?.message;

        if (message) {
          this.$notify({
            type: 'success',
            text: message
          });
        }
      } catch (error) {
        const message = error?.message;
        const description = error?.description;
        const errorMessage = `${description}: ${message}`;

        this.showModal(modalsId.NOTIFY, {
          title: this.$t('Main.Ui.acImportInventoryFromXlsx'),
          text: (message && description) ? errorMessage : this.$t('Error.HeadImportFromXlsx')
        });
      } finally {
        this.$refs.uploader[0].value = '';

        ampli.forecastReportImported({
          'Project Id': this.projectId
        });
      }
    },
    setCheckbox(control) {
      this.setControl({ [control.requestKey]: control.val });
    },
    changeSelect(label, control) {
      if (!control.selection) {
        return;
      }

      const val = control.selection.find(el => el.label === label);

      this.setControl({
        [control.requestKey]: val.value
      });
    },
    getItems(selection) {
      if (!selection) {
        return [];
      }

      return selection.map(item => ({
        label: item.label,
        disabled: item.style === DISABLED_STYLE
      }));
    },
    getValue(val, selection) {
      if (!selection) {
        return '';
      }

      const item = selection.find(item => item.value === val);

      return item?.label || '';
    },
    isLabel(control) {
      return control.name && control.type !== this.controlType.button;
    },
    getAggregateLabel(selection) {
      if (!selection) {
        return '';
      }

      const labels = selection.reduce((acc, el) => {
        if (el?.status) {
          acc.push(el.label);
        }

        return acc;
      }, []);

      return labels.join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/reports/forecast-reports-toolbar.scss";
</style>
