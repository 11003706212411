<template>
  <div class="sl-pagination">
    <v-pagination
      v-model="vModel"
      :length="paginationLength"
      :total-visible="paginationCount"
    />
    <div class="sl-pagination__info">
      {{ paginationInfo }}
      <span
        v-if="selected"
        class="sl-pagination__selected"
      >
        {{ $t('Web.Selection', { 1: selected }) }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SlPagination',
  props: {
    page: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      default: 100
    },
    paginationCount: {
      type: Number,
      default: 7
    },
    selected: {
      type: Number,
      default: 0
    }
  },
  computed: {
    vModel: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit('change-page', val);
      }
    },
    paginationLength() {
      return Math.ceil(+this.totalCount / this.perPage);
    },
    itemsFrom() {
      return ((this.page - 1) * this.perPage) + 1;
    },
    itemsTo() {
      const to = this.page * this.perPage;

      if (to > this.totalCount) {
        return this.totalCount;
      }

      return to;
    },
    paginationInfo() {
      return this.$t('Web.Table.PaginationLabel', {
        1: this.itemsFrom,
        2: this.itemsTo,
        3: this.totalCount
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/shared/sl-pagination.scss";
</style>